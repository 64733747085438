div#wallet-collection {

  .small-gallery-img {
    max-height: 8rem;
    max-width: 10rem;
  }

  div.card-footer {

    > div > div {
      flex-direction: column;

      > div:last-child {
        align-items: center;
        display: flex;

        > span {
          margin-left: 5px;
          margin-right: 0;
        }

      }

    }

  }

}
