@import 'bootstrap/scss/bootstrap.scss';
@import '../../assets/styles/main';

div.collection-list-item {

  div.card {

    div.card-image {
      height: 150px;
      justify-content: center;
      align-items: center;
      display: flex;
      object-fit: contain;

      //@include media-breakpoint-up(sm) {
      //  height: 272px;
      //}
      //
      //@include media-breakpoint-up(md) {
      //  height: 380px;
      //}
      //
      //@include media-breakpoint-up(xl) {
      //  height: 300px;
      //}

      img {
        align-items: center;
        border-radius: 0;
        justify-content: center;
        max-height: 150px;
        object-fit: contain;
        width: 100%;
      }

    }

    div.card-body {
      padding-bottom: 20px;

      //@include media-breakpoint-up(sm) {
      //  height: 220px;
      //}
      //
      //@include media-breakpoint-up(md) {
      //  height: 240px;
      //}
      //
      //@include media-breakpoint-up(xl) {
      //  height: 222px;
      //}

      p.collection-description {
        color: #4E4E4E;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 18px;

        @include media-breakpoint-up(sm) {
          height: 80px;
        }

        @include media-breakpoint-up(md) {
          height: 100px;
        }

        @include media-breakpoint-up(xl) {
          height: 82px;
        }

      }

      a.button {
        text-align: center;
      }

    }

  }

}
