@import 'bootstrap/scss/bootstrap.scss';
//@import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
//@import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
@import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '../../assets/styles/main';


ul.react-bootstrap-table-page-btns-ul {

  @include media-breakpoint-up(sm) {
    float: right;
  }

  li.page-item {

    a.page-link {
      color: $blue-orb-explorer;
    }

    &.active {

      a.page-link {
        background-color: $blue-orb-explorer;
        border-color: $blue-orb-explorer;
        color: #fff;
      }

    }

  }

}

div.react-bootstrap-table {
  overflow-x: scroll;
  width: 100%;

  > table {
    table-layout: inherit !important;

    th {
      font-size: 17px;
      text-align: center;
    }

    td {
      color: $secondary-color;
      font-size: 14px;
      text-align: center;

      > div.cell-image {
        margin: auto;
        text-align: center;
      }

      > div.cell-blockchain {

        > img {
          margin-right: 3px;
        }

      }

      a {
        color: $blue-orb-explorer;
        text-decoration: none;
      }

    }

  }

}

span.react-bs-table-sizePerPage-dropdown {

  > button {
    background-color: $blue-orb-explorer;
    border-color: $blue-orb-explorer;
  }

  a {
    color: $blue-orb-explorer;
    text-decoration: none;
  }

}
