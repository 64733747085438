div#withdraw-modal-4 {

  div.modal-content {

    div.modal-body {

      > div#orb-image {
        margin: auto;
        max-width: 200px;
      }

      > div {

        > p {
          margin: auto;
          max-width: 450px;
          text-align: center;
        }

        > h5 {
          text-align: center;
        }

        > div#buttons {
          align-items: normal;
          display: flex;
          flex-direction: column;
          margin: auto;
          max-width: 200px;
          padding: 10px 0;
        }

      }

      > div#spinner-container {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: auto;
      }

    }

  }

}
