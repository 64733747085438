@import 'bootstrap/scss/bootstrap.scss';

#header {
  display: flex;

  @include media-breakpoint-up(md) {
    height: 75px;
  }

  #logo-container {

    #logo {

      > img {
        width: 150px;
      }

    }

  }

  #search-field {
    max-width: 340px;

    @include media-breakpoint-down(md) {
      margin: auto;
      text-align: center;
    }

    > input {
      background: #F7F7F7 0 0 no-repeat padding-box;
      border-radius: 23px;
      border-color: #F7F7F7;
      font-size: 14px;
      z-index: 0;
    }

    button {
      background: transparent linear-gradient(240deg, #3488C8 0%, #33A1FD 100%) 0 0 no-repeat padding-box;
      border: 0;
      border-radius: 23px;
      color: #fff;
      height: 100%;
      position: absolute;
      right: 4px;
      width: 66px;
      z-index: 1;

      &:focus {
        box-shadow: none;
      }

    }

  }

  #main-navigation {
    justify-content: flex-end;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding-top: 15px;
    }

    #header-navbar {

      @include media-breakpoint-up(md) {
        margin-left: 50px;
      }

      > a.navbar-nav-link {
        color: rgba(4, 17, 29, 0.75);
        font-weight: bold;
        padding: 5px 10px;
        text-decoration: inherit;

        &:hover {
          color: rgba(4, 17, 29, 1);
        }
      }

      > div {
        margin: 0 !important;
        max-width: 100%;
        text-align: center;
        //overflow: hidden;

        > button {
          background: transparent;
          border: none;
          color: rgba(4, 17, 29, 0.75);
          font-weight: bold;
          text-transform: none;

          &:hover {
            box-shadow: none;
            color: rgba(4, 17, 29, 1);
          }

        }

        //> div {
        //
        //  @include media-breakpoint-down(md) {
        //    max-width: 100%;
        //    //width: 100%;
        //
        //    div, a, span{
        //      max-width: 100%;
        //      //width: 100%;
        //    }
        //
        //    a{
        //      //display: none;
        //      background: green;
        //    }
        //
        //  }
        //
        //}

      }

    }

  }


}


