div#main-navigation{

  div.dropdown-menu {

    > a {

      &:hover{
        background-color: transparent;
      }

    }

  }

}
