div#page-wallet{
  margin-top: 50px;

  div.header-elements{
    display: none;
  }

  div#wallet-block{
    margin: auto;
    padding-bottom: 30px;
    text-align: center;
    max-width: 500px;
  }

}
