div#deposit-modal-2 {

  div.modal-content {

    div.modal-body {
      margin: auto;
      text-align: center;

      > h6, p {
        text-align: center;
      }

      svg#deposit-qr-code{
        margin: 20px 0;
        cursor: pointer;
      }

      > div#buttons {
        align-items: normal;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 200px;

        > button {
          margin-bottom: 5px;
        }

      }

      > div#last-transaction {

        > div#deposit-address-loading{
          height: 32px;
          margin-top: 8px;
        }

      }

    }

  }

}
