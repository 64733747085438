
#parallax {
  background-attachment: fixed;
  background-color: rgb(51, 161, 253);
  background-image: url('../../assets/images/parallax-optimzed.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: 700px;
  margin-bottom: 24px;
  padding-bottom: 72px;

  > #main-block {
    background-image: linear-gradient(rgb(255, 255, 255, 0.5), transparent);
    height: 700px;

    #tagline {
      color: #fff;
      font-weight: 800;
      font-size: 40px;
      padding-top: 160px;
    }

    h1 {
      font-style: italic;
      margin-bottom: 30px;
      opacity: 0.7;
    }

  }

}
