@import '../../assets/styles/main';

div#page-collection {
  margin-top: 50px;

  div#collection-description {
    margin: auto;
    padding-bottom: 30px;
    text-align: center;
    max-width: 500px;

    div#collection-image {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      margin-bottom: 10px;
      margin-top: 20px;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    > p {
      color: $secondary-color;
    }

  }

  div#collections-tabs-tabpane-collections {

    div.card {

      div.card-image {
        display: flex;
        justify-content: center;

        img {
          max-height: 150px;
          width: 100%;
        }

      }

      div.card-body {
        p.collection-description {
          color: #4E4E4E;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          line-height: 18px;
        }
      }

    }

  }

}


