@import 'bootstrap/scss/bootstrap.scss';

div#spotlight-component {
  margin-top: 40px;

  div.row{
    margin: auto;
    max-width: 100%;

    > h2 {
      font-size: 25px;
      margin-bottom: 15px;
    }

    div.card{

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }

      div.card-image{

      }

      div.card-body{
        padding-bottom: 20px;

        a.button{
          text-align: center;
        }

      }

    }

  }

}

//div#spotlight-component {
//
//  h2 {
//    text-align: center;
//    margin: 0 0 56px !important;
//  }
//
//  .card {
//    border: 1px solid rgb(229, 232, 235);
//    box-shadow: none !important;
//
//    .card-img-actions {
//      height: 206px;
//    }
//
//    .card-body {
//      height: 200px;
//
//      h3 {
//        color: #fff;
//        font-weight: bold;
//        font-size: 21px;
//      }
//
//      h4 {
//        color: #fff;
//        max-width: 300px;
//        margin: 0 auto 26px auto;
//        padding: initial;
//        line-height: 21px;
//        font-size: 17px;
//      }
//
//      a {
//        border: 1px solid #fff;
//        border-radius: 10px;
//        color: #fff;
//        font-size: 14px;
//        font-weight: 500;
//        padding: 7px 13px 8px;
//      }
//    }
//  }
//}


