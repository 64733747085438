@import 'bootstrap/scss/bootstrap.scss';
@import './fonts.scss';

$primary-color: #161616;
$secondary-color: #4e4e4e;
$blue-orb-explorer: #32a0fc;
$layout-boxed-width: 1200px;

body {
  color: $primary-color;
  font-family: 'Lato', sans-serif;
  font-weight: 400;

  @include media-breakpoint-down(xl) {
    padding: 0 50px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 25px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 20px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }

  #root {
    margin: auto;
    max-width: $layout-boxed-width;

    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 25px;
    }

    .form-control:focus {
      box-shadow: none;
    }

    div.card {
      border: 0 solid;
      box-shadow: 0 30px 80px #00000011;
      border-radius: 8px;
    }

    a {
      &.nav-link {
        color: $primary-color;
      }

      &:focus {
        box-shadow: none;
      }

      &.button {
        background: transparent linear-gradient(78deg, #33a1fd 0%, #3488c8 100%) 0 0 no-repeat padding-box;
        border-radius: 5px;
        color: #fff;
        display: block;
        margin: auto;
        max-width: 100%;
        padding: 8px 0;
        text-decoration: none;
        width: 200px;
      }
    }

    div.title-line {
      margin-bottom: 10px;
      padding: 0 0 1px 0;
      position: relative;

      &:before {
        background: transparent linear-gradient(265deg, #3488c8 0%, #33a1fd 100%) 0 0 no-repeat padding-box;
        border-radius: 2px;
        bottom: 0;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        right: 0;
        width: 32px;
      }

      h3 {
        font-size: 17px;
        text-align: left;
      }

    }

  }

  .inline-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;

    display: inline-block;
  }

  .blockchain-img {
    @extend .inline-img;
    margin-right: 10px;

    &-ethereum {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/ethereum.png');
    }

    &-counterparty {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/counterparty.png');
    }

    &-firstOasisKlaytn {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/firstOasisKlaytn.png');
    }

    &-klaytn {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/klaytn.png');
    }

    &-matic {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/matic.png');
    }

  }

  button.btn-primary,
  a.btn-primary{
    background: transparent linear-gradient(78deg, #33a1fd 0%, #3488c8 100%) 0 0 no-repeat padding-box;
    border: 0 solid;
    border-radius: 5px;
    color: #fff;
  }

  input.form-control{

    &:focus,
    &:active {
      border-color: #ced4da;
      box-shadow: none;
    }

  }

  button.btn-primary,
  a.btn-primary,
  button.btn-light,
  button.btn-secondary {

    &:focus,
    &:active:focus {
      box-shadow: none;
    }

  }

}
