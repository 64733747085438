@import './../../assets/styles/main.scss';

.dropdown-menu {
  min-width: 350px !important;
}

.connect-button {
  min-width: 200px;
}

.wallet-img {
  @extend .inline-img;

  &-metamask {
    @extend .wallet-img;
    background-image: url('./../../assets/images/wallets/metamask.svg');
  }

  &-kaikas {
    @extend .wallet-img;
    background-image: url('./../../assets/images/wallets/kaikas.png');
  }

  &-web3 {
    @extend .wallet-img;
    background-image: url('./../../assets/images/wallets/web3.png');
  }

  &-counterparty {
    @extend .wallet-img;
    background-image: url('./../../assets/images/wallets/counterparty.png');
  }

  &-firstoasis {
    @extend .wallet-img;
    background-image: url('./../../assets/images/wallets/firstOasis.png');
  }
}
